import { MouseEventHandler, ComponentPropsWithoutRef } from 'react';
import {
  ActionIcon,
  ActionIconProps,
  createStyles,
  Image as MantineImage,
  ImageProps as MantineImageProps,
  Modal,
  ModalProps,
  Progress,
  Transition,
} from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons';

export interface ImageProps extends MantineImageProps {
  withProgress?: boolean;
  progress?: number;
  withDeleteButton?: boolean;
  onDelete?: ActionIconProps & ComponentPropsWithoutRef<'button'>['onClick'];
}

interface State {
  visible: boolean;
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
  },

  action: {
    position: 'absolute',
    top: theme.spacing.xs - 8,
    right: theme.spacing.xs - 8,
  },

  progress: {
    position: 'relative',
    bottom: theme.spacing.xl,
    marginLeft: theme.spacing.xs,
    marginRight: theme.spacing.xs,
  },
}));

const TrashButton = (
  props: ActionIconProps & ComponentPropsWithoutRef<'button'>
): JSX.Element => (
  <ActionIcon color="red" value="filled" {...props}>
    <IconTrash />
  </ActionIcon>
);

const Image = ({
  className,
  withProgress = false,
  progress,
  withDeleteButton = false,
  onDelete: customOnDelete,
  ...props
}: ImageProps): JSX.Element => {
  const { classes, cx } = useStyles();
  const [state, setState] = useSetState<State>({
    visible: false,
  });

  const showOverlay: MouseEventHandler<HTMLDivElement> = () => {
    setState({ visible: true });
  };

  const hideOverlay: ModalProps['onClose'] = () => {
    setState({ visible: false });
  };

  const onDelete: ImageProps['onDelete'] = (e) => {
    e.stopPropagation();
    customOnDelete?.(e);
  };

  return (
    <>
      <Modal
        opened={state.visible}
        centered
        withCloseButton
        onClose={hideOverlay}
      >
        <MantineImage {...props} />
      </Modal>

      <div
        className={cx(classes.container, className)}
        style={{ cursor: 'pointer' }}
        onClick={showOverlay}
      >
        <MantineImage {...props} />
        {withDeleteButton && (
          <TrashButton className={classes.action} onClick={onDelete} />
        )}

        <Transition transition="fade" mounted={withProgress}>
          {(styles) => (
            <div style={styles}>
              <Progress className={classes.progress} value={progress} />
            </div>
          )}
        </Transition>
      </div>
    </>
  );
};

export default Image;
