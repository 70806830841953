import { useState } from 'react';
import Image from 'next/image';
import {
  Burger,
  Group,
  MediaQuery,
  Header as MantineHeader,
  useMantineTheme,
} from '@mantine/core';
import Logo from '@/public/images/logo.svg';

interface HeaderProps {
  onBurgerClick?: (opened: boolean) => void;
}

const Header = ({ onBurgerClick }: HeaderProps): JSX.Element => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  const onClick = () => {
    setOpened((o) => {
      const next = !o;
      onBurgerClick?.(next);
      return next;
    });
  };

  return (
    <MantineHeader height={70} p="xs">
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <MediaQuery largerThan="md" styles={{ display: 'none' }}>
          <Burger
            opened={opened}
            onClick={onClick}
            size="sm"
            color={theme.colors.gray[6]}
            mr="xl"
          />
        </MediaQuery>

        <Group>
          <Image
            src={Logo}
            layout="intrinsic"
            alt="logo"
            width="280"
            height="50%"
          />
        </Group>
      </div>
    </MantineHeader>
  );
};

export default Header;
