import { ReactNode, useState } from 'react';
import {
  AppShell,
  Box,
  Container,
  ContainerProps,
  MediaQuery,
  useMantineTheme,
} from '@mantine/core';
import { IconCalendarEvent, IconCalendarPlus, IconUsers } from '@tabler/icons';
import Header from './Header';
import SideNav, { SideNavProps } from './SideNav';

const links: SideNavProps['data'] = [
  {
    icon: IconCalendarEvent,
    label: 'イベント',
    link: '/admin/events',
  },
  {
    icon: IconUsers,
    label: 'ユーザー',
    link: '/admin/users',
  },
  {
    icon: IconCalendarPlus,
    label: 'イベント管理',
    link: '/admin/event_manage',
  },
];

export interface LayoutProps {
  containerProps?: ContainerProps;
  children?: ReactNode;
}

const Layout = ({
  containerProps = {},
  children,
}: LayoutProps): JSX.Element => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      }}
      padding="md"
      navbarOffsetBreakpoint="md"
      asideOffsetBreakpoint="sm"
      fixed
      header={<Header onBurgerClick={(opened) => setOpened(opened)} />}
      navbar={<SideNav data={links} opened={opened} />}
    >
      <>
        {/* xs より大きいとき非表示 */}
        <MediaQuery largerThan="xs" styles={{ display: 'none' }}>
          <Box>{children}</Box>
        </MediaQuery>
        {/* xs より小さいとき非表示 */}
        <MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
          <Container {...containerProps}>{children}</Container>
        </MediaQuery>
      </>
    </AppShell>
  );
};

export default Layout;
