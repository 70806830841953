import type { AppProps } from 'next/app';
import Head from 'next/head';
import { MantineProvider, createEmotionCache } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import { RouterTransition } from '@/components';
import 'react-image-crop/dist/ReactCrop.css';
import '@/styles/globals.css';

const emotionCache = createEmotionCache({ key: 'mantine', prepend: false });

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <link rel="icon" type="image/png" href="/favicon.png" />
        <meta name="theme-color" content="#000000" />
        <meta httpEquiv="Content-Language" content="ja" />
        <meta name="google" content="notranslate" />
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>

      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        emotionCache={emotionCache}
      >
        <ModalsProvider
          modalProps={{
            centered: true,
            closeButtonLabel: '閉じる',
            closeOnClickOutside: true,
            closeOnEscape: true,
            withCloseButton: true,
            withinPortal: true,
          }}
        >
          <NotificationsProvider
            autoClose={20 * 1000}
            limit={3}
            position="bottom-right"
          >
            <RouterTransition />
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Component {...pageProps} />
          </NotificationsProvider>
        </ModalsProvider>
      </MantineProvider>
    </>
  );
}

export default MyApp;
