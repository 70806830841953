import {
  MediaHTMLAttributes,
  SourceHTMLAttributes,
  DetailedHTMLProps,
  ComponentPropsWithoutRef,
} from 'react';
import {
  ActionIcon,
  ActionIconProps,
  createStyles,
  Progress,
  Transition,
} from '@mantine/core';
import { MIME_TYPES } from '@mantine/dropzone';
import { IconTrash } from '@tabler/icons';

type HTMLVideoProps = DetailedHTMLProps<
  MediaHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>;

type HTMLSourceProps = DetailedHTMLProps<
  SourceHTMLAttributes<HTMLSourceElement>,
  HTMLSourceElement
>;

export interface VideoProps
  extends Omit<HTMLVideoProps, 'controls'>,
    Pick<HTMLSourceProps, 'src'> {
  /* source tag の props */
  sourceProps?: Omit<HTMLSourceProps, 'type' | 'src'>;

  /* プログレスバーを表示するかどうか */
  withProgress?: boolean;

  /* プログレスバーの値 */
  progress?: number;

  /* 削除ボタンを表示するかどうか */
  withDeleteButton?: boolean;

  /* 動画にコントロールを表示するかどうか */
  withControls?: boolean;

  /* 削除ボタンがクリックされた際に実行する処理 */
  onDelete?: ActionIconProps & ComponentPropsWithoutRef<'button'>['onClick'];
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
  },

  action: {
    position: 'absolute',
    top: theme.spacing.xs - 8,
    right: theme.spacing.xs - 8,
  },

  progress: {
    position: 'relative',
    bottom: theme.spacing.xl,
    marginLeft: theme.spacing.xs,
    marginRight: theme.spacing.xs,
  },
}));

const TrashButton = (
  props: ActionIconProps & ComponentPropsWithoutRef<'button'>
): JSX.Element => (
  <ActionIcon color="red" value="filled" {...props}>
    <IconTrash />
  </ActionIcon>
);

const Video = ({
  className,
  src,
  withProgress,
  progress,
  withDeleteButton,
  withControls,
  onDelete,
  sourceProps,
  ...props
}: VideoProps): JSX.Element => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container, className)}>
      <video controls={withControls} {...props}>
        <source src={src} type={MIME_TYPES.mp4} {...sourceProps} />
        <track kind="captions" />
      </video>

      {withDeleteButton && (
        <TrashButton className={cx(classes.action)} onClick={onDelete} />
      )}

      <Transition transition="fade" mounted={withProgress === true}>
        {(styles) => (
          <div style={styles}>
            <Progress className={classes.progress} value={progress} />
          </div>
        )}
      </Transition>
    </div>
  );
};

export default Video;
