import { forwardRef } from 'react';
import { usePagination } from '@mantine/hooks';
import {
  DefaultProps,
  MantineColor,
  MantineNumberSize,
  Selectors,
  useComponentDefaultProps,
} from '@mantine/styles';
import { Group, GroupProps, Select } from '@mantine/core';
import { PaginationItem } from './PaginationItem';
import useStyles, { PaginationStylesParams } from './Pagination.styles';

export type PaginationStylesNames = Selectors<typeof useStyles>;

export interface PaginationProps
  extends DefaultProps<PaginationStylesNames, PaginationStylesParams>,
    Omit<GroupProps, 'classNames' | 'styles' | 'onChange'> {
  /** Active item color from theme, defaults to theme.primaryColor */
  color?: MantineColor;

  /** Active initial page for uncontrolled component */
  initialPage?: number;

  /** Controlled active page number */
  page?: number;

  /** Total amount of pages */
  total: number;

  /** Limit of per pages */
  limit?: 20 | 50 | 100;

  /** Siblings amount on left/right side of selected page */
  siblings?: number;

  /** Amount of elements visible on left/right edges */
  boundaries?: number;

  /** Callback fired after change of each page */
  onChange?: (page: number) => void;

  /** Callback fired after change of select */
  onChangeLimit?: (limit: number) => void;

  /** Callback to control aria-labels */
  getItemAriaLabel?: (
    page: number | 'dots' | 'prev' | 'next' | 'first' | 'last'
  ) => string | undefined;

  /** Spacing between items from theme or number to set value in px, defaults to theme.spacing.xs / 2 */
  spacing?: MantineNumberSize;

  /** Predefined item size or number to set width and height in px */
  size?: MantineNumberSize;

  /** Predefined item radius or number to set border-radius in px */
  radius?: MantineNumberSize;

  /** Show/hide jump to start/end controls */
  withEdges?: boolean;

  /** Show/hide prev/next controls */
  withControls?: boolean;

  /** Determines whether all controls should be disabled */
  disabled?: boolean;
}

const defaultProps: Partial<PaginationProps> = {
  initialPage: 1,
  limit: 20,
  siblings: 1,
  boundaries: 1,
  size: 'md',
  radius: 'sm',
  withEdges: false,
  withControls: true,
};

export const Pagination = forwardRef<HTMLDivElement, PaginationProps>(
  (props, ref) => {
    const {
      classNames,
      styles,
      page,
      limit,
      initialPage,
      color,
      total,
      siblings,
      boundaries,
      size,
      radius,
      onChange,
      onChangeLimit,
      getItemAriaLabel,
      spacing,
      withEdges,
      withControls,
      sx,
      unstyled,
      disabled,
      ...others
    } = useComponentDefaultProps(
      'Pagination',
      defaultProps,
      props
    ) as Required<PaginationProps>;

    const { classes, theme } = useStyles(
      { color, size, radius },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      { classNames, styles, unstyled, name: 'Pagination' }
    );

    const { range, setPage, next, previous, active, first, last } =
      usePagination({
        page,
        siblings,
        total,
        onChange,
        initialPage,
        boundaries,
      });

    const items = range.map((pageNumber, index) => (
      <PaginationItem
        key={index}
        page={pageNumber}
        active={pageNumber === active}
        aria-current={pageNumber === active ? 'page' : undefined}
        aria-label={
          typeof getItemAriaLabel === 'function'
            ? getItemAriaLabel(pageNumber)
            : undefined
        }
        tabIndex={pageNumber === 'dots' ? -1 : 0}
        data-dots={pageNumber === 'dots' || undefined}
        data-active={pageNumber === active || undefined}
        className={classes.item}
        onClick={pageNumber !== 'dots' ? () => setPage(pageNumber) : undefined}
        disabled={disabled}
      />
    ));

    return (
      <Group
        spacing={spacing || theme.fn.size({ size, sizes: theme.spacing }) / 2}
        ref={ref}
        sx={sx}
        unstyled={unstyled}
        {...others}
        role="navigation"
      >
        {withEdges && (
          <PaginationItem
            page="first"
            onClick={first}
            aria-label={
              getItemAriaLabel ? getItemAriaLabel('first') : undefined
            }
            aria-disabled={active === 1 || disabled}
            className={classes.item}
            disabled={active === 1 || disabled}
          />
        )}

        {withControls && (
          <PaginationItem
            page="prev"
            onClick={previous}
            aria-label={getItemAriaLabel ? getItemAriaLabel('prev') : undefined}
            aria-disabled={active === 1 || disabled}
            className={classes.item}
            disabled={active === 1 || disabled}
          />
        )}

        {items}

        {withControls && (
          <PaginationItem
            page="next"
            onClick={next}
            aria-label={getItemAriaLabel ? getItemAriaLabel('next') : undefined}
            aria-disabled={active === total || disabled}
            className={classes.item}
            disabled={active === total || disabled}
          />
        )}

        {withEdges && (
          <PaginationItem
            page="last"
            onClick={last}
            aria-label={getItemAriaLabel ? getItemAriaLabel('last') : undefined}
            aria-disabled={active === total || disabled}
            className={classes.item}
            disabled={active === total || disabled}
          />
        )}

        <Select
          styles={{
            input: { width: '70px' },
          }}
          defaultValue={String(limit)}
          data={[
            { value: '20', label: '20' },
            { value: '50', label: '50' },
            { value: '100', label: '100' },
          ]}
          onChange={(val) => onChangeLimit(Number(val || 20))}
        />
      </Group>
    );
  }
);

Pagination.displayName = 'Pagination';
