import { HTMLProps, ReactNode, useRef } from 'react';
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  ReactCropProps,
} from 'react-image-crop';
import { Button, Center, Group } from '@mantine/core';
import { closeModal, openModal } from '@mantine/modals';
import { randomId, useSetState } from '@mantine/hooks';
import { useMount } from 'ahooks';

export interface ImageCropProps {
  file: File;
  onComplete?: (file: Maybe<File>) => void | Promise<void>;
}

interface State {
  crop?: Crop;
  src?: string;
}

const ImageCrop = ({ file, onComplete: customOnComplete }: ImageCropProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [state, setState] = useSetState<State>({});

  useMount(async () => {
    const { toBase64String } = await import('@/features/candidate/hooks/utils');
    const src = await toBase64String(file);
    setState({ src });
  });

  const onChange: ReactCropProps['onChange'] = (crop) => {
    setState({ crop: crop });
  };

  const onImageLoad: HTMLProps<HTMLImageElement>['onLoad'] = (e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    const cropSizeMax = () => (width <= height ? height : width);
    const cropSizeMin = () => (width >= height ? height : width);
    const aspectRate = 400 / cropSizeMax();
    const cropSize = cropSizeMin() * aspectRate;
    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: cropSize,
        },
        1,
        width,
        height
      ),
      cropSize,
      cropSize
    );

    setState({ crop });
  };

  const onComplete: ReactCropProps['onComplete'] = (crop) => {
    return new Promise<Maybe<File>>((resolve) => {
      if (!imgRef.current || !canvasRef.current) {
        return resolve(null);
      }
      const image: HTMLImageElement = imgRef.current;
      const canvas = canvasRef.current;
      canvas.style.width = `${crop.width}px`;
      canvas.style.height = `${crop.height}px`;

      const ctx = canvas.getContext('2d');
      if (ctx === null) {
        document.body.removeChild(canvas);
        throw new Error('No 2d context');
      }

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = 400;
      canvas.height = 400;

      ctx.imageSmoothingQuality = 'medium';

      const cropX = crop.x * scaleX;
      const cropY = crop.y * scaleY;
      const cropWidth = crop.width * scaleX;
      const cropHeight = crop.height * scaleY;
      ctx.drawImage(image, cropX, cropY, cropWidth, cropHeight, 0, 0, 400, 400);
      ctx.restore();

      canvas.toBlob(
        (blob) => {
          if (blob === null) {
            return resolve(null);
          } else {
            return resolve(
              new File([blob], file.name, {
                type: file.type,
              })
            );
          }
        },
        file.type,
        'medium'
      );
    }).then((result) => customOnComplete?.(result));
  };

  return (
    <>
      <div className="hidden">
        <canvas ref={canvasRef} className="object-contain" />
      </div>

      <ReactCrop
        crop={state.crop}
        aspect={1}
        circularCrop
        onChange={onChange}
        onComplete={onComplete}
      >
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={state.src}
          alt="image crop"
          ref={imgRef}
          onLoad={onImageLoad}
        />
      </ReactCrop>
    </>
  );
};

export default ImageCrop;

export interface ImageCropSettings {
  file: File;
  title: ReactNode;
  description?: ReactNode;
  onComplete?: (file: Maybe<File>) => void | Promise<void>;
}

export function openImageCrop(settings: ImageCropSettings) {
  const modalId = randomId();

  let result: Maybe<File> = null;
  const onComplete: ImageCropProps['onComplete'] = (file: Maybe<File>) => {
    result = file;
  };

  const onClick = () => {
    closeModal(modalId);
    settings.onComplete?.(result);
  };

  openModal({
    modalId,
    title: settings.title,
    children: (
      <>
        {settings.description}

        <Center>
          <ImageCrop file={settings.file} onComplete={onComplete} />
        </Center>

        <Group position="right" mt="md">
          <Button onClick={onClick}>確定</Button>
        </Group>
      </>
    ),
  });
}
