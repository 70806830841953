import { useMemo } from 'react';
import Link from 'next/link';
import {
  Anchor,
  Breadcrumbs as BaseComponent,
  BreadcrumbsProps as BaseProps,
  Text,
} from '@mantine/core';

export interface BreadcrumbsItem {
  title: string;
  href?: string;
  disabled?: boolean;
}

export interface BreadcrumbsProps extends Omit<BaseProps, 'children'> {
  data: BreadcrumbsItem[];
}

const Breadcrumbs = ({ data, ...props }: BreadcrumbsProps): JSX.Element => {
  const items = useMemo(() => {
    return data.map(({ title, href, disabled }) =>
      disabled ? (
        <Text key={title} color="gray">
          {title}
        </Text>
      ) : (
        <Link key={title} href={href || '#'} passHref>
          <Anchor>{title}</Anchor>
        </Link>
      )
    );
  }, [data]);

  return <BaseComponent {...props}>{items}</BaseComponent>;
};

export default Breadcrumbs;
