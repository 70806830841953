import clsx from 'clsx';
import { Text, useMantineTheme, Group } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { IconMovie, IconUpload, IconX } from '@tabler/icons';
import { UploadProps } from './types';
import { useStyles } from './styles';

export type VideoUploadProps = UploadProps;

const VideoUpload = ({
  className,
  disabled,
  ...props
}: VideoUploadProps): JSX.Element => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <>
      <Dropzone
        className={clsx(className, disabled && classes.disabled)}
        accept={[MIME_TYPES.mp4]}
        disabled={disabled}
        {...props}
      >
        <Group position="center" spacing="xs" style={{ pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload
              size={50}
              stroke={1.5}
              color={
                theme.colors[theme.primaryColor][
                  theme.colorScheme === 'dark' ? 4 : 6
                ]
              }
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size={50}
              stroke={1.5}
              color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconMovie size={50} stroke={1.5} />
          </Dropzone.Idle>

          <div>
            <Text size="md" inline>
              ビデオをここにドロップまたはクリックしてファイルを選択してください
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              アップロードできるファイルは最大 32MB までです
            </Text>
          </div>
        </Group>
      </Dropzone>
    </>
  );
};

export default VideoUpload;
