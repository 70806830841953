import clsx from 'clsx';
import { Text, useMantineTheme, Group } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconPhoto, IconUpload, IconX } from '@tabler/icons';
import { UploadProps } from './types';
import { useStyles } from './styles';

export type ImageUploadProps = UploadProps;

const ImageUpload = ({
  className,
  disabled,
  ...props
}: ImageUploadProps): JSX.Element => {
  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <>
      <Dropzone
        className={clsx(className, disabled && classes.disabled)}
        accept={IMAGE_MIME_TYPE}
        disabled={disabled}
        {...props}
      >
        <Group position="center" spacing="xs" style={{ pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <IconUpload
              size={50}
              stroke={1.5}
              color={
                theme.colors[theme.primaryColor][
                  theme.colorScheme === 'dark' ? 4 : 6
                ]
              }
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              size={50}
              stroke={1.5}
              color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto size={50} stroke={1.5} />
          </Dropzone.Idle>

          <div>
            <Text size="md" inline>
              画像をここにドロップまたはクリックしてファイルを選択してください
            </Text>
            <Text size="sm" color="dimmed" inline mt={7}>
              アップロードできるファイルはそれぞれ最大 5MB までです
            </Text>
          </div>
        </Group>
      </Dropzone>
    </>
  );
};

export default ImageUpload;
