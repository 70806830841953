import { ComponentPropsWithoutRef } from 'react';
import { Box, BoxProps } from '@mantine/core';
import clsx from 'clsx';

type Gap =
  | 0
  | 0.5
  | 1
  | 1.5
  | 2
  | 2.5
  | 3
  | 3.5
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;

type Columns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface MasonryProps
  extends BoxProps,
    ComponentPropsWithoutRef<'div'> {
  gap?: Gap;
  columns?: Columns;
  xs?: Columns;
  sm?: Columns;
  md?: Columns;
  lg?: Columns;
  xl?: Columns;
}

export interface MasonryColProps extends ComponentPropsWithoutRef<'div'> {
  gap?: Gap;
}

const Col = ({
  gap,
  className,
  children,
  ...props
}: MasonryColProps): JSX.Element => (
  <div
    className={clsx(
      {
        'mb-0': gap === 0,
        'mb-0.5': gap === 0.5,
        'mb-1': gap === 1,
        'mb-1.5': gap === 1.5,
        'mb-2': gap === 2,
        'mb-2.5': gap === 2.5,
        'mb-3': gap === 3,
        'mb-3.5': gap === 3.5,
        'mb-4': gap === 4,
        'mb-5': gap === 5,
        'mb-6': gap === 6,
        'mb-7': gap === 7,
        'mb-8': gap === 8,
        'mb-9': gap === 9,
        'mb-10': gap === 10,
        'mb-11': gap === 11,
        'mb-12': gap === 12,
      },
      className
    )}
    {...props}
  >
    {children}
  </div>
);

function Masonry({
  gap,
  columns,
  xs,
  sm,
  md,
  lg,
  xl,
  className,
  children,
  ...props
}: MasonryProps): JSX.Element {
  return (
    <Box
      className={clsx(
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          'gap-0': gap === 0,
          'gap-0.5': gap === 0.5,
          'gap-1': gap === 1,
          'gap-1.5': gap === 1.5,
          'gap-2': gap === 2,
          'gap-2.5': gap === 2.5,
          'gap-3': gap === 3,
          'gap-3.5': gap === 3.5,
          'gap-4': gap === 4,
          'gap-5': gap === 5,
          'gap-6': gap === 6,
          'gap-7': gap === 7,
          'gap-8': gap === 8,
          'gap-9': gap === 9,
          'gap-10': gap === 10,
          'gap-11': gap === 11,
          'gap-12': gap === 12,
          'columns-1': columns === 1,
          'columns-2': columns === 2,
          'columns-3': columns === 3,
          'columns-4': columns === 4,
          'columns-5': columns === 5,
          'columns-6': columns === 6,
          'columns-7': columns === 7,
          'columns-8': columns === 8,
          'columns-9': columns === 9,
          'columns-10': columns === 10,
          'columns-11': columns === 11,
          'columns-12': typeof columns === 'number' && columns >= 12,
          'xs:columns-1': xs === 1,
          'xs:columns-2': xs === 2,
          'xs:columns-3': xs === 3,
          'xs:columns-4': xs === 4,
          'xs:columns-5': xs === 5,
          'xs:columns-6': xs === 6,
          'xs:columns-7': xs === 7,
          'xs:columns-8': xs === 8,
          'xs:columns-9': xs === 9,
          'xs:columns-10': xs === 10,
          'xs:columns-11': xs === 11,
          'xs:columns-12': typeof xs === 'number' && xs >= 12,
          'sm:columns-1': sm === 1,
          'sm:columns-2': sm === 2,
          'sm:columns-3': sm === 3,
          'sm:columns-4': sm === 4,
          'sm:columns-5': sm === 5,
          'sm:columns-6': sm === 6,
          'sm:columns-7': sm === 7,
          'sm:columns-8': sm === 8,
          'sm:columns-9': sm === 9,
          'sm:columns-10': sm === 10,
          'sm:columns-11': sm === 11,
          'sm:columns-12': typeof sm === 'number' && sm >= 12,
          'md:columns-1': md === 1,
          'md:columns-2': md === 2,
          'md:columns-3': md === 3,
          'md:columns-4': md === 4,
          'md:columns-5': md === 5,
          'md:columns-6': md === 6,
          'md:columns-7': md === 7,
          'md:columns-8': md === 8,
          'md:columns-9': md === 9,
          'md:columns-10': md === 10,
          'md:columns-11': md === 11,
          'md:columns-12': typeof md === 'number' && md >= 12,
          'lg:columns-1': lg === 1,
          'lg:columns-2': lg === 2,
          'lg:columns-3': lg === 3,
          'lg:columns-4': lg === 4,
          'lg:columns-5': lg === 5,
          'lg:columns-6': lg === 6,
          'lg:columns-7': lg === 7,
          'lg:columns-8': lg === 8,
          'lg:columns-9': lg === 9,
          'lg:columns-10': lg === 10,
          'lg:columns-11': lg === 11,
          'lg:columns-12': typeof lg === 'number' && lg >= 12,
          'xl:columns-1': xl === 1,
          'xl:columns-2': xl === 2,
          'xl:columns-3': xl === 3,
          'xl:columns-4': xl === 4,
          'xl:columns-5': xl === 5,
          'xl:columns-6': xl === 6,
          'xl:columns-7': xl === 7,
          'xl:columns-8': xl === 8,
          'xl:columns-9': xl === 9,
          'xl:columns-10': xl === 10,
          'xl:columns-11': xl === 11,
          'xl:columns-12': typeof xl === 'number' && xl >= 12,
        },
        className
      )}
      {...props}
    >
      {children}
    </Box>
  );
}

Masonry.Col = Col;

export default Masonry;
